<template>
<div>
    <CAlert v-if="hasErrors" color="danger" class="py-1 px-2 mt-3">Cannot save changes. Please check the errors marked in red.</CAlert>
    <CTabs :activeTab.sync="activeTab" class="mt-3" addTabsClasses="pt-0" >
        <CTab v-for="tl in tlsInternal" :key="tl.language" class="pt-0 mt-0">
            <template slot="title">
                <span :class="getTabClasses(tl)">{{ tl.language }}</span>
            </template>
            <template>
                <CAlert :color="(!tl.title || !tl.description) ? 'warning' : 'info'" 
                        class="mb-0 mt-3 px-3 py-1"
                >If you leave fields empty, the default title and description will be used.</CAlert>
                <div class="row mt-3">
                    <CInput horizontal label="Title" 
                            add-label-classes="strong editor-label-col" 
                            add-wrapper-classes="pl-0 ml-0"
                            add-input-classes="text-primary"
                            class="ml-2 col"
                           :placeholder="title"
                            v-model="tl.title" 
                           :isValid="isTitleValidOrEmpty"
                            maxlength="80"
                    />
                </div>
                <div class="row">
                    <CTextarea horizontal label="Description"
                            class="ml-2 col description-input"
                            add-label-classes="strong editor-label-col" 
                            add-wrapper-classes="pl-0 ml-0"
                            add-input-classes="text-primary"
                            style="min-height: 42px"
                            v-model="tl.description" 
                           :placeholder="description"
                           :isValid="isDescriptionValidOrEmpty"
                    />
                </div>
            </template>
        </CTab>
    </CTabs>       
</div>
</template>
<script>
import { mapActions } from 'vuex';
import { actionNames } from '@/store/modules/products/actions';
export default {
    props: {
        translations: Array,
        title: String,
        description: String,
        mode: String
    },
    data() {
        return {
            modeInternal: 'bonus',
            activeTab: null,
            tlsInternal: null,
            hasErrors: false
        }
    },
    methods: {
        ...mapActions({
            updateBonusTranslations: actionNames.UPDATE_BONUS,
            updateProductTranslations: actionNames.UPDATE_PRODUCT,
        }),
        async save() {            
            if (this.isAllValid()) {
                await this.setTranslations({sku:this.sku, translations: this.tlsInternal});
                this.$emit("saved");
            }
            else {
                this.hasErrors = true;
            }
        },
        getTabClasses(tl) {
            const classes = [];

            if (!this.isValid(tl)) {
                classes.push('text-danger');
                if (this.hasErrors) {
                    classes.push('strong');
                }
            }
            else if (tl.title && tl.description) {
                classes.push('text-black');
            }
            else if (tl.title || tl.description) {
                classes.push('text-info');
            }
            else /* (!tl.title || !tl.description)*/ {
                classes.push('text-warning');
            }
            
            if (this.translations[this.activeTab].language == tl.language) {
                classes.push('strong');
            }
            
            return classes;
        },
        // Validation
        isTitleValid: (title) => title && title.length <= 80 ? null : false,
        isTitleValidOrEmpty(title) { 
            return title ? title.length == 0 || this.isTitleValid(title) : null; 
        },
        isDescriptionValid: (text) => text && text.length <= 150 ? null : false,
        isDescriptionValidOrEmpty(text) { 
            return text ? text.length == 0 || this.isDescriptionValid(text) : null; 
        },
        isValid(tl) {
            if (tl) {
                if (this.isTitleValidOrEmpty(tl.title) === false ||
                    this.isDescriptionValidOrEmpty(tl.description) === false)
                {
                    return false;
                }

                return true;
            }
        },
        isAllValid() {
            return this.tlsInternal.every(tl => this.isValid(tl) !== false);
        }
    },
    watch:{
        tlsInternal: {
            handler() {
                this.hasErrors = false;
            }, 
            deep: true
        }
    },
    created() {
        this.activeTab = 0;
        this.tlsInternal = [];
        if (this.translations) {
            this.tlsInternal = this.translations.map(tl => {return { language:tl.language, title:tl.title, description:tl.description}});
        }

        this.setTranslations = (this.mode == "product") ? this.updateProductTranslations : this.updateBonusTranslations;
    }
}
</script>
