<template>
    <CCard style="min-height: 120px;"> 
    <CCardHeader class="pt-2 pb-0">  
        <div class="float-right mt-1 pt-0 mb-1 d-flex align-items-center" v-if="bonus">
            <CBadge v-if="!isBonusAvailable" color="danger" class="mt-1 mr-2 pt-1 pb-1" style="transform: translateY(-1px)">Unavailable</CBadge>
            <CBadge v-else color="success" class="mt-1 mr-2 pt-1 pb-1" style="transform: translateY(-1px)">Available</CBadge>
            <CSwitch size="sm" color="success" variant="3d" :disabled="isLoading || !bonus || !canEdit" :checked.sync="isBonusAvailable" @update:checked="onIsBonusAvailableChange"></CSwitch>  
        </div>
        <div class="float-left d-flex flex-row mt-1 mb-1" style="width:auto">
            <CIcon name="cil-gift" size="lg" class="mr-2" /><h5 class="">Bonus Details: {{sku}}</h5>     
        </div>    
        <CSpinner v-if='isLoading' class="ml-2 mt-2" size="sm" color="primary" />                 
    </CCardHeader>
        <CCardBody class="pt-3">    
            <CAlert v-if="!isLoading && !bonus" color="danger" class="mt-0 mb-0">
                Failed to load bonus. <CLink class="strong" to="/bonuses">Click here</CLink> to return to bonus list. 
            </CAlert>  
            <template v-if="!!bonus">
            <div class="d-flex">
                <div id="imgDropzone" class="vue-dropzone">
                <ImageUploader v-if="!bonus.isProduct" mode="bonus" :sku="sku" :merchantId="merchantId" :imageFile="imageUrl" />
                <CImg v-if="bonus.isProduct" :src="imageUrl" width="100" height="100" />
                </div>
                <div class="ml-3 d-flex flex-column align-items-start">
                    <div class="mb-1">
                        <strong>{{ bonus.title}}</strong>
                    </div>
                    <CBadge v-if="(!bonus.description)" color="light">Description missing</CBadge><span v-else>{{ bonus.description }}</span>
                    <CButton v-if="bonus.isProduct" :to="`/products/view/${bonus.sku}`" color="primary" class="action-btn mt-2" style="text-align: left"><CIcon name="cil-arrow-right" size="sm" class="mr-2" style="transform: translateY(-2px)" />Product Configuration</CButton>
                    <CButton v-if="canEdit" color="danger" :class="{'action-btn': bonus.isProduct, 'mt-2': true, 'pr-3': true}" style="text-align: left" @click="onRemoveBonus"><CIcon name="cil-trash" size="sm" class="mr-2" style="transform: translateY(-2px);" />Remove {{ bonus.isProduct ? 'as' : ''}} Bonus</CButton>
                </div>                   
            </div>
        </template>    
        </CCardBody>
        <CCardHeader v-if="!!bonus && hasTranslations" class="pt-2 pb-2 top-border">     
            <EditCombo v-if="!bonus.isProduct"            
                    v-show="canEdit"
                    class="float-right mr-2 mt-0 mb-0" 
                    :disabled="isLoading"
                    :edit="editTranslations"
                    @edit="onEditTranslations"
                    @cancel="onCancelEditTranslations"
                    @save="onSaveTranslations"
            /> 
            <CLink v-else v-show="canEdit" color="primary" :to="`/products/view/${bonus.sku}?edit=tl&tl=${selectedTranslation}`" class="strong float-right mr-2"><CIcon name="cil-pencil" size="sm" class=" ml-1 mr-0 mb-1" /> Edit </CLink>
            <h6 class="float-left m-0 mt-1">Translations</h6>             
        </CCardHeader> 
        <CCardBody class="pt-0">
            <CTabs v-if="hasTranslations && !editTranslations" :activeTab.sync="activeTranslationTab">
                <CTab v-for="tl in bonus.translations" :key="tl.language" addTabsClasses="info" class="pt-3">
                    <template slot="title">
                        <span :class="{'text-warning': tl.isMissing}">{{ tl.language }}</span>
                    </template>
                    <template v-if="!tl.isMissing">                    
                        <div class="pl-2">
                        <div>
                            <strong :class="{'text-secondary': !tl.title}">{{ tl.title ? tl.title : bonus.title }}</strong>
                        </div>
                        <span :class="{'text-secondary': !tl.description}">{{ tl.description ? tl.description : bonus.description }}</span>
                        </div>
                    </template>
                    <template v-else>        
                        <div class="pl-2">                
                            <strong class="text-secondary">{{ bonus.title }}</strong>
                            <p class="text-secondary pb-0 mb-0">{{ bonus.description }}</p>
                            <CBadge v-if="!bonus.isProduct" color="warning" class="py-1 px-2 mt-2" @click="onEditTranslations" style="cursor: pointer">Add translation</CBadge>
                        </div>
                    </template>
                </CTab>
            </CTabs>     
            <TranslationEditor v-if="hasTranslations && editTranslations" 
                ref="tlEditor"
                :translations="bonus.translations" 
                :title="bonus.title"
                :description="bonus.description"
            />
        </CCardBody>
        <ConfirmActivation ref="confirmActiveModal" 
            :activatFnc="setActiveFnc" 
            :title="`Change bonus to ${(isBonusAvailable ? 'available' : 'unavailable')}`"
            :message="boxMessage"
            loadingMessage="Updating bonus availability..."
            @cancel="onCancelIsAvailable" 
        /> 

        <ConfirmActivation ref="confirmRemove" 
            :activatFnc="removeAsBonus" 
            :title="`Remove ${bonus && bonus.isProduct ? 'as ': ''}Bonus`"
            :message="`Do you really want to remove this ${bonus && bonus.isProduct ? 'product as ': ''}bonus? Make sure to check your product rewards after doing so!`"
            loadingMessage="Updating bonus availability..."
            color="danger"
        /> 
    </CCard>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/products/actions';
import ImageUploader from '@/components/ImageUploader.vue';
import ConfirmActivation from '@/components/modals/ConfirmActivation';
import EditCombo from '@/components/EditCombo.vue';
import TranslationEditor from '@/components/TranslationEditor.vue';
import { EDIT_REWARD, mapPermissions } from '@/permissions';

export default {    
    props: {
        sku: String,
        translation: String
    },
    components: {
        ImageUploader,
        ConfirmActivation,
        EditCombo,
        TranslationEditor
    },
    data() { 
        return {
            activeTranslationTab: 0,
            isBonusAvailable: false,
            setActiveFnc: null,
            editTranslations: false
        }
    },
    methods: {
        ...mapActions({
            loadBonus: actionNames.LOAD_BONUS,
            setAvailable: actionNames.SET_BONUS_AVAILABLE,
            removeBonus: actionNames.REMOVE_BONUS
        }),       
        async initBonus() {
            await this.loadBonus({sku:this.sku});      
            // TODO: Catch, Redirect      

            if (this.translation) {                
                const tl = this.bonus.translations.find(o => o.language == this.translation);
                if (tl) {
                    this.activeTranslationTab = this.bonus.translations.indexOf(tl);                                        
                }
            }

            this.isBonusAvailable = this.bonus ? this.bonus.isAvailable : false;
        },
        onCancelIsAvailable() {
            const o = this;       
            setTimeout(function() {
                o.isBonusAvailable = o.bonus.isAvailable;
            }, 100);
        },
        async onIsBonusAvailableChange(available) {
            if (available) 
            {
                await this.setAvailable({isAvailable:available});
            }
            else 
            {
                const o = this;        
                this.setActiveFnc = async () => {
                    await o.setAvailable({isAvailable:available});
                };
                const modal = o.$refs.confirmActiveModal;
                modal.show();
            }
        },
        onEditTranslations() {            
            this.editTranslations = true;
            const o = this;
            const activeTab = this.activeTranslationTab;

            setTimeout(function() {
                o.$refs.tlEditor.activeTab = activeTab;
            });
        },
        onCancelEditTranslations() {
            const activeTab = this.$refs.tlEditor.activeTab;  
            this.activeTranslationTab = activeTab;
            this.editTranslations = false;
        },
        async onSaveTranslations() {
            await this.$refs.tlEditor.save();
            const activeTab = this.$refs.tlEditor.activeTab;            
            this.editTranslations = false;
            this.activeTranslationTab = activeTab;
        },
        onRemoveBonus() {
            this.$refs.confirmRemove.show();
        },
        async removeAsBonus() {
            await this.removeBonus({sku:this.bonus.sku});
            this.$refs.confirmRemove.cancel();

            this.$router.replace("/bonuses");
        }
    },
    computed: {
        ...mapPermissions({
            canEdit: EDIT_REWARD
        }),
        hasTranslations() {
            return this.bonus && this.bonus.translations && this.bonus.translations.length > 0;
        },        
        imageUrl() {
            return this.bonus && this.bonus.imageFile ? this.bonus.imageFile: null
        },
        ...mapGetters({
            merchantId: 'merchantId',
            bonus: 'products/product',
            isLoading: 'products/loading'
        }),
        boxMessage() {
            return `Do you want change the bonus status to ${(this.isBonusAvailable ? "available" : "unavailable")}? ${this.isBonusAvailable ? "" : "Please make sure to update products accordingly!"}`;
        },
        selectedTranslation() {
            const tl = this.bonus.translations[this.activeTranslationTab];
            return tl ? tl.language : ""; 
        }
    },
    created() {
        this.initBonus();
    },
    watch: {
        merchantId() {
            this.initBonus();
        },
        sku() {
            this.initBonus();
        },       
        translation() {
            this.initBonus();
        }
    }
}
</script>
<style scoped>
.action-btn {
    width: 185px;
}
.action-btn button {
    text-align: left;
}
</style>
