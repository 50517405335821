<template>
<CModal
    :color="colorValue"
    :show.sync="showModal"
    :closeOnBackdrop='false'
    :title="title"
    addContentClasses="pb-0"
    class="pb-0"
>
    <span>{{message}}</span>
    <template #footer>
        <div v-if="loading" class="mr-auto d-flex align-items-center">
            <CSpinner class="mr-2" size="sm" color="info" /><strong>{{loadingMessage}}</strong>
        </div>
        <CButton :disabled='loading' color="secondary" class="my-0" @click="onCancel">Cancel</CButton>
        <CButton :disabled='loading' :color="colorValue" class="my-0" @click="onConfirm">Confirm</CButton>
    </template>
</CModal>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        message: String,
        loadingMessage: String,
        title: String,
        activatFnc: Function,
        color: String
    },
    emits: ['cancel','confirmed'],
    data() {
        return {
            showModal: false,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'products/loading'
        })
    },
    methods: {
      show() {
          this.showModal = true;
      },
      cancel() {
          this.showModal = false;
      },
      onCancel() {
          this.showModal = false;
          this.$emit("cancel");
      },
      async onConfirm() {
          if (this.activatFnc) {
              await this.activatFnc();
          }
        this.showModal = false;
          this.$emit("confirmed");
      }
    },
    created() {
        this.colorValue = this.color ? this.color : "info"
    }
}
</script>
