<template>
    <vue-dropzone 
        v-c-tooltip="'Ideal size: 400 x 400 px'"
        class="dropzone"
        id="imgDropzone"
        ref="imgDropzone"
        :useCustomSlot="true"
        :include-styling="true"
        :options="dropzoneOptions"         
        @vdropzone-max-files-exceeded="maxFilesExceeded"
        @vdropzone-error="fileError"
        @vdropzone-success="completed"
        @vdropzone-queue-complete="queueCompleted"
        @vdropzone-sending="sending"
    >
        <img v-if="(imageUrl && !isMissing)" :src="imageUrl" width="100" height="100" style="margin-top: -10px" @error="imgMissing($event)"/>
        <CIcon v-if="isMissing" name='cil-image' size="custom" width="100" height="100" style="margin-top: -10px; background: #ddd" class="opacity-1"></CIcon>
        <CBadge class="upload mt-2">Upload here</CBadge>
    </vue-dropzone>  
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import { mapGetters, mapActions } from 'vuex';
import { PUSH_ERROR, PUSH_SUCCESS } from '@/store'
import { SET_PRODUCT_IMAGE } from '@/store/modules/products/mutations';
import { getProductImageUploadUrl, getBonusImageUploadUrl } from '@/api';
import { REFRESH } from '@/store/modules/auth/action-types';

export default {
    props: {
        sku: String,
        mode: String,
        imageFile: String
    },
    components: {
        vueDropzone: vue2Dropzone,        
    },
    data() {
        return {
            isMissing: false,
            imageUrl: null,            
            isProduct: false,

            // Dropzone Options:
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                withCredentials: true,
                createImageThumbnails: false,
                autoProcessQueue: true,
                addRemoveLinks: true,
                acceptedFiles:'image/*',
                maxFiles: 1,
                parallelUploads: 1, 
                uploadMultiple: false,
            }
        }
    },
    computed: {
        ...mapGetters({
            merchantId: 'merchantId',
            token: 'token',
            tokenExpiration: 'tokenExpiration'
        })
    },
    methods: {
        ...mapActions({refresh: REFRESH}), 
        init() {
            const isProduct = this.mode != 'bonus';
            this.isMissing = false;
            this.dropzoneOptions.url = isProduct ? getProductImageUploadUrl(this.merchantId, this.sku) : getBonusImageUploadUrl(this.merchantId, this.sku);
            this.imageUrl = this.imageFile;
        },
        /* eslint-disable*/
        async sending(file, xhr, formData) {  
            console.log("sending...")          
            if (!this.tokenExpiration || (this.tokenExpiration <= (new Date().getTime() + 1500))) {
                try {
                    await this.refresh();
                }
                catch(e){
                    this.$store.commit(PUSH_ERROR, {message: `An error occured. Please login again`});
                    return;
                }
            }
            xhr.setRequestHeader('Authorization', `bearer ${this.token}`);
        },
        maxFilesExceeded(file) {
            this.$refs.imgDropzone.removeFile(file);
        },
        /* eslint-disable */
        fileError(file,message,xhr) {
            if (file && !xhr) {
                this.$refs.imgDropzone.removeFile(file);
                this.$store.commit(PUSH_ERROR, {message});
            }
            else {
                this.$store.commit(PUSH_ERROR, {message: "Failed to upload product image"});
            }
        },  
        queueCompleted() {
            const zone = this.$refs.imgDropzone;
            zone.removeAllFiles();
        },
        completed(file, response) {        
            const zone = this.$refs.imgDropzone;
            zone.removeAllFiles();
            this.$store.commit(PUSH_SUCCESS, {message: "Product image successfully uploaded."});
            if (response.sku == this.sku) {
                this.$store.commit('products/' + SET_PRODUCT_IMAGE, {sku: response.sku, imageFile: response.imageFile});
            }
        },
        imgMissing(e)
        {
            this.isMissing = true;
        }
    },
    created(){
        this.init();
    },
    watch: {
        sku() {
            this.init();
        },
        merchantId() {
            this.init();
        },
        imageFile() {
            this.init();
        },
        mode() {            
            this.init();
        }
    }
}
</script>


<style>
#imgDropzone .dz-message {
    margin: 0px;
    width: 100px; height: 100px;    
}
#imgDropzone
{
    min-width: 150px; width: 150px; height: 150px; max-height: 150px;
    display: flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
}
</style>

<style scoped> 
.badge.upload {
    background: #666;
    color: white;
}
</style>
